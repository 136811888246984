.custom-toast-success {
    background-color: green;
}

.custom-toast-error {
    background-color: red;
}

.custom-toast-warning {
    background-color: yellow;
}
